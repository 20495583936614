body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: "Montserrat",sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}
.main-step-form::-webkit-scrollbar {
  display: none;
}
footer{
  background-image: linear-gradient(to bottom, rgba(90, 103, 113, 1), rgba(90, 103, 113, 1));
}
@media (min-width: 768px) { 
  footer{
    background-image: linear-gradient(to bottom, rgba(90, 103, 113, 0), rgba(90, 103, 113, 1));
  }
}